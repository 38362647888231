//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { enterpriseHttp } from "@/api/apiEnterpriseService.js";
import env from "@/../env.js";
import comTabSke from "../skeComponents/comTabSke";
import TabList from "@/components/TabList/index.vue";
import ServiceHall from "@/pages/Home/components/companyService/components/ServiceHall/ServiceHallCell.vue";
import NeedHall from "@/pages/Home/components/companyService/components/NeedHall/NeedHall.vue";
import TransferHall from "@/pages/Home/components/companyService/components/TransferHall/TransferHall.vue";
import CompanyTransfer from "@/pages/Home/components/companyService/components/TransferHall/CompanyTransfer.vue";
import BussinessHall from "@/pages/Home/components/companyService/components/BussinessHall/BussinessHall.vue";
import comServerSke1 from "../skeComponents/comServerSke1";
import comServerSke2 from "../skeComponents/comServerSke2";
import comSmallTBSke from "../skeComponents/comSmallTBSke";
import Bussiness from "./components/BussinessHall/Bussiness.vue";
export default {
  components: {
    CommonTag: () => import("../commonTag"),
    NoData: () => import("@/pages/Home/components/noData.vue"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    TabList,
    ServiceHall,
    NeedHall,
    TransferHall,
    CompanyTransfer,
    comTabSke,
    comServerSke1,
    comServerSke2,
    comSmallTBSke,
    BussinessHall,
    Bussiness
  },
  data() {
    return {
      currentMenuIndex: 0,
      isClose: false,
      isMore: false,
      isLoadData: false,
      typeList: [],
      list: [],
      load: false,
      firstType: "",
      firstTypeName: "商标交易",
      currentPage: 1,
      pubEnum: {
        0: "个人",
        1: "企业",
        2: "团队"
      },
      isEmpty: false
    };
  },
  created() {
    this.refreshData();
  },
  async fetch() {
    try {
      const res = await enterpriseHttp.getServiceType();
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.map(item => ({
          name: item.serviceClass,
          value: item.id
        }));
        this.typeList = [{ name: "不限", value: "" }, ...list];
      }
    } catch (error) {}
    // try {
    //   const parmas = {
    //     asc: false,
    //     limit: 6,
    //     moneyAsc: false,
    //     pageSize: 1,
    //     serviceName: '',
    //     sortType: 1,
    //     type: 1
    //   }
    //   const res = await enterpriseHttp.getServiceHallList(parmas)
    //   const { data, code, msg } = res.data
    //   if(+code === 0) {
    //     const list = data.records || []
    //     this.isLoadData = list.length === parmas.limit
    //     this.list = this.list.concat(list)
    //     this.isMore = this.currentPage < data.pages;
    //     this.load = false;
    //     return
    //   }
    //   this.load = false;
    // } catch (error) {}
  },
  mounted() {},
  methods: {
    changeTag(index) {
      this.currentMenuIndex = index;
      this.typeList = [];
      this.firstType = "";
      this.firstTypeName = "商标交易";
      this.refreshData();
    },
    refreshData() {
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.list = [];
      this.requestHttpList();
    },
    loadMore() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.isClose = true;
        this.requestHttpList();
      }
    },
    /**
     * 一级菜单点击
     */
    firstTabChange(data) {
      const { value, name } = data;
      this.firstType = value;
      this.firstTypeName = name;
      this.refreshData();
    },
    /**
     * 发起http列表请求
     */
    requestHttpList() {
      // return
      if (this.load) {
        return false;
      }
      this.load = true;
      switch (this.currentMenuIndex) {
        case 0:
          !this.typeList.length && this.getServiceList();
          this.firstType ? this.getServiceListData() : this.getServiceAllList();
          break;
        case 1:
          !this.typeList.length && this.getServiceType();
          // this.getServiceComNoList();
          this.getServiceHallList();
          break;
        // 隐藏需求大厅
        case 2:
          !this.typeList.length && this.getServiceType();
          // this.getNeedTypeList();
          this.getNeedList();
          break;
        case 3:
          this.getTransferHallTypeList();
          this.requestTransfer();
          break;
        default:
          break;
      }
    },
    // 转让大厅详情
    toDetail(item) {
      const qiye = env[process.env.environment].qiye_SERVICE;
      const { id } = item;
      if (this.firstTypeName === "商标交易" || !this.firstTypeName) {
        window.open(`${qiye}/colTraDetail?id=${id}`);
        return;
      }
      if (this.firstTypeName === "专利交易") {
        window.open(`${qiye}/colPatDetail?id=${id}`);
        return;
      }
      if (this.firstTypeName === "版权交易") {
        window.open(`${qiye}/colCopDetail?id=${id}`);
        return;
      }
      if (this.firstTypeName === "资质交易") {
        window.open(`${qiye}/qualificationDetail?id=${id}`);
        return;
      }
    },
    // 转让大厅列表
    requestTransfer() {
      if (this.firstTypeName === "商标交易" || !this.firstTypeName) {
        this.getTrademarkList();
        return;
      }
      if (this.firstTypeName === "专利交易") {
        this.getPatentList();
        return;
      }
      if (this.firstTypeName === "版权交易") {
        this.getCopyrightList();
        return;
      }
      if (this.firstTypeName === "资产转让") {
        this.getAssetsList();
        return;
      }
      if (this.firstTypeName === "资质交易") {
        this.getQualificationsList();
        return;
      }
      this.getCompanyTransferList();
    },
    /**
     * 商标列表
     */
    async getTrademarkList() {
      const pramas = {
        asc: false,
        pageNum: this.currentPage,
        pageSize: 12,
        // sortType: 2,
        status: 1
      };
      const res = await enterpriseHttp.getTransferHallList(
        pramas,
        "v1/trademark/pageList"
      );
      const { data, code, msg } = res.data;
      if (+code === 0) {
        let list = data.records || [];
        list =
          list.length &&
          list.map(item => {
            return {
              ...item,
              imgField: item.img,
              nameField: item.name,
              rangeField: item.range,
              publishTypeField: this.pubEnum[item.publishType],
              categoryField: item.category + "类",
              priceField: item.price,
              labelField: item.label,
              createTimeField: +item.createTime
            };
          });
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        this.isEmpty = this.list.length === 0;
        return;
      }
      this.load = false;
    },
    /**
     * 专利列表
     */
    async getPatentList() {
      const pramas = {
        asc: false,
        pageNum: this.currentPage,
        pageSize: 12,
        // sortType: 2,
        status: 1
      };
      const res = await enterpriseHttp.getTransferHallList(
        pramas,
        this.firstType
      );
      const { data, code, msg } = res.data;
      if (+code === 0) {
        let list = data.records || [];
        list =
          list.length &&
          list.map(item => {
            return {
              ...item,
              imgField: item.img,
              nameField: item.name,
              rangeField: item.range,
              industryNameField: item.industryName,
              publishTypeField: this.pubEnum[item.publishType],
              categoryField: item.categoryName,
              priceField: item.price,
              labelField: item.label,
              createTimeField: +item.createTime
            };
          });
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**
     * 版权列表
     */
    async getCopyrightList() {
      const pramas = {
        asc: false,
        pageNum: this.currentPage,
        pageSize: 12,
        // sortType: 0,
        status: 1
      };
      const res = await enterpriseHttp.getTransferHallList(
        pramas,
        this.firstType
      );
      const { data, code, msg } = res.data;
      if (+code === 0) {
        let list = data.records || [];
        list =
          list.length &&
          list.map(item => {
            return {
              ...item,
              imgField: item.img,
              nameField: item.name,
              rangeField: item.range,
              publishTypeField: this.pubEnum[item.publishType],
              categoryNameField: item.categoryName,
              priceField: item.price,
              labelField: item.label,
              createTimeField: +item.createTime
            };
          });
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**
     * 资产列表
     */
    async getAssetsList() {
      const pramas = {
        assetsType: 0,
        limit: 12,
        name: "",
        pageSize: this.currentPage,
        publishType: "",
        timeAsc: false,
        timeAscIndex: true
      };
      const res = await enterpriseHttp.getTransferHallList(
        pramas,
        this.firstType
      );
      const { data, code, msg } = res.data;
      if (+code === 0) {
        let list = data.records || [];
        list =
          list.length &&
          list.map(item => {
            return {
              ...item,
              imgField: item.commodityImages,
              nameField: item.assetsName,
              rangeField: item.range,
              publishTypeField: this.pubEnum[item.publishType],
              categoryField: item.typeName,
              priceField: item.salePrice,
              labelField: item.label,
              createTimeField: +item.createTime
            };
          });
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 资质交易
    async getQualificationsList() {
      const params = {
        asc: false,
        pageSize: this.currentPage,
        limit: 12,
        status: 1
      };
      const res = await enterpriseHttp.getTransferHallList(
        params,
        this.firstType
      );
      const { data, code } = res.data;
      console.log(data, code, 123123);
      if (+code === 0) {
        let list = data.records || [];
        list =
          list.length &&
          list.map(item => {
            return {
              ...item,
              imgField: item.img,
              nameField: item.qualificationName,
              rangeField: item.range,
              publishTypeField: this.pubEnum[item.publishType],
              categoryNameField: item.categoryName,
              priceField: item.qualificationPrice,
              labelField: item.label,
              createTimeField: +item.createTime
            };
          });
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        console.log(list, "list");
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**
     * 公司转让列表
     */
    async getCompanyTransferList() {
      const pramas = {
        companyName: "",
        limit: 12,
        minPrice: "",
        name: null,
        page: this.currentPage,
        status: 1,
        timeAsc: false,
        timeAscIndex: true,
        userType: ""
      };
      const res = await enterpriseHttp.getTransferHallList(
        pramas,
        this.firstType
      );
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.records || [];
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 转让大厅一级菜单列表
    getTransferHallTypeList() {
      const p = new Promise(res => {
        res([
          { name: "商标交易", value: "v1/trademark/pageList" },
          { name: "专利交易", value: "v1/patent/pageList" },
          { name: "版权交易", value: "v1/copyright/pageList" },
          // {name:'资产转让', value:'assets/list'},
          // {name:'公司转让', value:'v1/companyTransfer/pcList'}
          { name: "资质交易", value: "qualification/qualificationPageList" }
        ]);
      });
      p.then(res => {
        this.typeList = res;
      });
    },
    // 需求大厅菜单
    getNeedTypeList() {
      const p = new Promise(res => {
        res([
          { name: "全部", value: "" },
          { name: "商标需求", value: 1 },
          { name: "专利需求", value: 2 },
          { name: "版权需求", value: 3 },
          // {name:'公司需求', value:4},
          // {name:'资产需求', value:6},
          { name: "服务需求", value: 5 }
        ]);
      });
      p.then(res => {
        this.typeList = res;
      });
    },
    async getNeedList() {
      const parmas = {
        asc: false,
        limit: 4,
        pageSize: this.currentPage,
        // requirementTypes: this.firstType ? this.firstType : 0,
        sortType: "1",
        categoryFirstId: this.firstType
      };
      const res = await enterpriseHttp.getNeedList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 服务企业企业no列表
    async getServiceComNoList() {
      const parmas = {
        asc: false,
        limit: 6,
        pageSize: this.currentPage,
        serviceClass: this.firstType,
        // serviceName: null,
        sortType: 1,
        type: 1
      };
      const res = await enterpriseHttp.getServiceComNoList(parmas);
      this.getServiceHallList(res);
      this.load = false;
    },
    // 服务企业列表数据
    async getServiceHallList() {
      this.load = true;
      console.log("");
      let params = {
        limit: 6,
        pageSize: 1,
        sortAsc: 0,
        complexSortType: 1,
        name: null,
        serviceClass: this.firstType || ""
      };
      let res = await enterpriseHttp.getServiceTeamList(params);
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.records || [];
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**
     * 服务企业-服务分类
     */
    async getServiceType() {
      const res = await enterpriseHttp.getServiceType();
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.map(item => ({
          name: item.serviceClass,
          value: item.id
        }));
        this.typeList = [{ name: "不限", value: "" }, ...list];
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**
     * 服务大厅--服务分类
     */
    async getServiceList() {
      const res = await enterpriseHttp.getServiceType();
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.map(item => ({
          name: item.serviceClass,
          value: item.id
        }));
        this.typeList = [{ name: "全部", value: "" }, ...list];
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 服务大厅全部列表数据
    async getServiceAllList() {
      const parmas = {
        limit: 6,
        pageSize: this.currentPage,
        page: this.currentPage,
        sortType: 1,
        asc: false,
        serviceClass: this.firstType || "",
        serverType: this.firstType || 0,
        complexAsc: true,
        serviceName: "",
        startMoney: ""
      };
      // {"page":1,"limit":20,"pageSize":1,"complexAsc":true,"asc":false,"serverType":0,"keyword":null}
      const res = await enterpriseHttp.getServiceAllList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list =
          data.records.map(item => {
            return {
              ...item,
              name: item.realName,
              serviceHall: {
                serviceName: item.title,
                chargeType: item.chargeType,
                serviceScore: item.serviceScore,
                serviceDescription: item.serviceDescription,
                createTime: item.createTime,
                foreignSales: item.foreignSales,
                servicePrice: item.servicePrice,
                className: item.className,
                serviceCover: item.serviceCover,
                teamSizeMin: item.teamSizeMin,
                teamSizeMax: item.teamSizeMax,
                serviceCity: item.city,
                name: item.realName,
                className: item.secondClassName,
                obJid:item.obJid,
                type:item.type,
                supplyType:item.supplyType
              }
            };
          }) || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.maxPage;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 服务大厅列表数据
    async getServiceListData() {
      const parmas = {
        page: 1,
        limit: 20,
        pageSize: 1,
        serviceClass: this.firstType,
        complexAsc: true,
        asc: false,
        startMoney: "",
        endMoney: "",
        serviceName: ""
      };

      // {"page":1,"limit":20,"pageSize":1,"complexAsc":true,"asc":false,"serverType":0,"keyword":null}
      const res = await enterpriseHttp.getServiceList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 0) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    }
  }
};
