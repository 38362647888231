//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { enterpriseHttp } from "@/api/apiEnterpriseService";
import env from "../../../../../../env";
import comSmallTBSke from "../../skeComponents/comSmallTBSke";
var defalut_url = require("@/assets/trads.png");
  function checkImgExists(imgurl) {
    return new Promise(function(resolve, reject) {
      var ImgObj = new Image();
      ImgObj.src = imgurl;
      ImgObj.onload = function(res) {
        resolve(true);
      }
      ImgObj.onerror = function(err) {
        resolve(false)
      }
    })
}
export default {
  name: "knowledge",
  components: {
    TabList: () => import("@/components/TabList/index.vue"),
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comSmallTBSke
  },
  props: {},
  data() {
    const validateImage = async (item, url, width, height, mode = 'fill', quality = '100') => {
      if (!url) {
        return this.defalut_url
      }
      const webPQuery = `/format,webp`
      let cutUrl = url.split('?')[0]
      let process = 'x-oss-process'
      if (cutUrl.includes('.myhuaweicloud.com')) {
        process = 'x-image-process'
      }
      let params = `${process}=image/resize,m_${mode},w_${width},h_${height},limit_0/quality,q_${quality}`
        let aa = await checkImgExists(`${cutUrl}?${params}${isSupportWebp ? webPQuery : ''}`)
        if(aa){
          this.$set(item, 'imageurlll', `${cutUrl}?${params}${isSupportWebp ? webPQuery : ''}`)
        } else {
          this.$set(item, 'imageurlll', this.defalut_url)
        }
    }
    const isSupportWebp = (function () {
      try {
        return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
      } catch (err) {
        return false
      }
    })
    return {
      searchType: "商标",
      menuList: [
        { name: "商标", value: 1 },
        { name: "版权", value: 2 },
        { name: "专利", value: 3 }
      ],
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      currentPage: 1,
      validateImage,
      defalut_url: require("@/assets/trads.png"),
      preLoad: {
        preLoad: 1.3,
        error: defalut_url,
        loading: defalut_url,
        attempt: 3
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.refreshData();
  },
  mounted() { },
  methods: {
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getList();
        this.isClose = true;
        return;
      }
    },
    clickTabItem(item) {
      const { name } = item;
      this.searchType = name;
      this.refreshData();
    },
    // 获取货物列表
    async getList() {
      this.loadingStep = true;
      const parmas = {
        characterLength: "",
        cityNo: "",
        combinationNo: "",
        companyName: "",
        companyNo: "",
        fieldClassId: "",
        industryNameNo: "",
        limit: 12,
        name: "",
        page: this.currentPage,
        provinceNo: "",
        state: "",
        timeSort: 1,
        type: "",
        typesOfPropertyRights: this.searchType
      };
      const res = await enterpriseHttp.knowledgeList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        data.records.forEach(item => {
          this.validateImage(item, item.newImage[0].fileUrl, 273, 170, 'fill')
        })
        const list = data.records
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        console.log(this.list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        return;
      }
      this.loadingStep = false;
    },
    // 进入企业
    toDetail(item) {
      const { id, companyNo, typesOfPropertyRights } = item;
      const tab = this.menuList.findIndex(
        item => item.name === typesOfPropertyRights
      );
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL
        }/company/${id}?no=${companyNo}&type=59&tab=${tab + 1}`
      );
    },
  }
};
