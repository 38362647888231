//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
import env from "@/../env.js";
import img from "@/assets/service_company.png";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      chargeTypeList: { 0: "按项目计费", 1: "按时间收费", 2: "按件计费" },
      defaultImg: require("@/assets/qiyedianpu.png")
    };
  },
  computed: {
    servicePrice() {
      let { servicePrice } = this.item.serviceHall || "";
      if (!servicePrice || servicePrice == -1) {
        return "议价";
      } else {
        return `${servicePrice ? servicePrice.toFixed(2) : "--"}元`;
      }
    },
    foreignSales() {
      let { foreignSales } = this.item.serviceHall || "";
      if (foreignSales) {
        return `销量：${foreignSales}`;
      } else {
        return "";
      }
    },
    serviceName() {
      let { serviceName } = this.item.serviceHall || "";
      if (serviceName) {
        return serviceName;
      } else {
        return "";
      }
    },
    className() {
      let { className } = this.item.serviceHall || "";
      if (className) {
        return className;
      } else {
        return "";
      }
    },
    chargeType() {
      let { chargeType } = this.item.serviceHall || "";
      if (chargeType == 1 || chargeType == 0 || chargeType == 2) {
        return chargeType;
      } else {
        return;
      }
    },
    imgUrl() {
      let { serviceCover = "" } = this.item.serviceHall || null;
      if (!!serviceCover && serviceCover[0] === "[") {
        let imgUrl = JSON.parse(serviceCover);
        return imgUrl[0];
      } else {
        // return img;
        return this.defaultImg;
      }
    },
    teamSize() {
      // if()
      let teamSizeMin = this.item.serviceHall.teamSizeMin || "";
      let teamSizeMax = this.item.serviceHall.teamSizeMax || "";
      if(!teamSizeMax && !teamSizeMax ){
        return '/'
      }
      return `${
        teamSizeMin
          ? teamSizeMax
            ? teamSizeMin + "-"
            : teamSizeMin + "人以上"
          : ""
      } ${teamSizeMax ? teamSizeMax + "人" : ""}`;
    },
    authTime() {
      if (!this.item.authTime) {
        return "";
      } else {
        let date = new Date().getFullYear();
        let authTime = new Date(this.item.authTime).getFullYear();
        return "入驻" + (date - authTime +( date - authTime > 0 ? 0 :  1)) + "年";
      }
    },
    serviceProvinces() {
      let serviceProvinces = JSON.parse(this.item.serviceHall.serviceCity);
      let str = "";
      serviceProvinces.forEach((item, index) => {
        str += item.name + (index === serviceProvinces.length - 1 ? "" : "/");
      });
      return str;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    imageCutting: imageCutting,
    toDetail() {
      // https://dev.gcw.net/company?no=202003167728469&subMenuId=6&type=1&thirdMenu=serve
      // https://dev.gcw.net/company?no=1407235165943631872&subMenuId=6&type=1&thirdMenu=serve
      let qifu = env[process.env.environment].qiye_SERVICE; 
      let jrqifu = env[process.env.environment].VUE_FINANCESERVICE; 
      const { id, serviceClass,obJid,type,supplyType} = this.item.serviceHall;
      if (this.activeIndex) {
        window.open(`${qifu}/serveDetail?no=${id}&typeId=${serviceClass}`);
      } else {
        //全部列表跳转
        switch (Number(type)) {
          case 0:
            window.open(`${qifu}/serveDetail?no=${obJid}`);
            break;
          case 1:
            window.open(`${jrqifu}/factoring-product-detail?id=${obJid}`);
            break;
          case 2:
            window.open(`${jrqifu}/guarantee-product-detail?id=${obJid}`);
            break;
          case 3:
            if (supplyType == 1) {
              window.open(`${jrqifu}/supply-cooperation-detail?id=${obJid}`); 
            }else{
              window.open(`${jrqifu}/supply-business-detail?id=${obJid}`); 
            }
            break;
          case 4:
            window.open(`${jrqifu}/bank-product-detail?id=${obJid}`); 
            break;
          default:
            break;
        }
      }
      
    }
  },
  filters: {
    formatDuring(mss) {
      const step = mss;
      const years = parseInt(step / (1000 * 60 * 60 * 24 * 365));
      const days = parseInt(
        (step % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24)
      );
      // const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      // const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      // const seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
      if (years > 0 && days > 0) {
        return `${years}年${days}天`;
      }
      if (years > 0) {
        return `${years}年`;
      }
      return `${days}天`;
    },
    scopPas(data) {
      if (!data || !data.length) return "/";
      let list = JSON.parse(data);
      const newList =
        (list && list.map(item => item.name || item.serviceClass)) || "";
      return newList.join("/");
    },
    filterRep(data) {
      if (!data) return "";
      let reg = new RegExp(",|、", "g");
      // return data.replace(/,|、/g, '｜')
      return data.replace(reg, "｜");
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (num < 0) return "议价";
      if (!num) return "";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      console.log(numStr);
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    score(score) {
      console.log("score", score);
      return score > 0 ? (+score).toFixed(1) : "5.0";
    }
  }
};
