//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  data: function() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    tagList: {
      type: Array,
      default: []
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  async asyncData({ params, error }) {
    // return {};
  },
  methods: {},
  created() {},
  mounted() {}
};
