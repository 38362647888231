//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
import env from "@/../env.js";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      defaultImg: require("@/assets/qiyedianpu.png"),
      defaultTag: ["商标需求", "专利需求", "版权需求"],
      
    };
  },
  computed: {
    authTime() {
      if (!this.item.enterTime) {
        return "";
      } else {
        let date = new Date().getFullYear();
        let enterTime = new Date(this.item.enterTime).getFullYear();
        return "入驻" + (date - enterTime + 1) + "年";
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    imageCutting: imageCutting,
    toDetail() {
      const qiye = env[process.env.environment].qiye_SERVICE;
      const { id, requirementTypes } = this.item.demand;
      window.open(
        `${qiye}/demandDetail?id=${id}&requirementTypes=${requirementTypes}&fromPath=/demand`
      );
    }
  },
  filters: {
    serviceProviderType(type) {
      switch (type) {
        case 0:
          return '个人'
        case 1:
          return '企业'
        case 2:
          return '团队'
        case -1:
          return '企业、团队、个人'
        default:
          '/'
          break
      }
    },
    status(status) {
      let a = {
        1: "报价中",
        2: "选定服务商",
        3: "已下架",
      };
      return a[status] ? a[status] : "/";
    },
    filterRep(data) {
      if (!data) return "";
      let reg = new RegExp(",|、", "g");
      // return data.replace(/,|、/g, '｜')
      return data.replace(reg, "｜");
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "议价";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 5) {
        const res = num.toFixed(2);
        return res + "元";
      } else if (numLen >= 5) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    // 入驻时间判断
    parseAuthTime (time) {
      const year = Math.ceil((Date.now() - time) / 31536000000)
      return `入驻${year}年`
    }
  }
};
