import { render, staticRenderFns } from "./specialSession.vue?vue&type=template&id=0a27f73a&scoped=true&"
import script from "./specialSession.vue?vue&type=script&lang=js&"
export * from "./specialSession.vue?vue&type=script&lang=js&"
import style0 from "./specialSession.vue?vue&type=style&index=0&id=0a27f73a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a27f73a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FilterSearch/template/index.js').default,FooterLoad: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FooterLoad/index.vue').default})
