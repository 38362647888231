//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { enterpriseHttp } from "@/api/apiEnterpriseService";
import env from "../../../../../../env";
import comSmallTBSke from "../../skeComponents/comSmallTBSke";
import { imageCutting } from "@/util/util.js";
export default {
  name: "recommend",
  components: {
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comSmallTBSke
  },
  props: {},
  data() {
    return {
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      currentPage: 1,
      defalut_url: require("@/assets/url_empty.png")
    };
  },
  computed: {},
  watch: {},
  filters: {
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  created() {
    this.refreshData();
  },
  mounted() {},
  methods: {
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getList();
        this.isClose = true;
        return;
      }
    },
    // 获取货物列表
    async getList() {
      this.loadingStep = true;
      const parmas = {
        cityNo: "",
        companyNameNo: "",
        dynamicTypeNo: "",
        industryNameNo: "",
        limit: 12,
        page: this.currentPage,
        provinceNo: ""
      };
      const res = await enterpriseHttp.dynamicList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.records.map(item => {
            return {
              ...item,
              _dynamicSummary:
                item.dynamicSummary && item.dynamicSummary.length > 15
                  ? item.dynamicSummary.substr(0, 15) + "..."
                  : item.dynamicSummary
            };
          }) || [];
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        return;
      }
      this.loadingStep = false;
    },
    // 进入企业
    toDetail(item) {
      const { id, companyNo } = item;
      // https://qiye.gcw.net/dynamicDetail?id=128&companyNo=201908306975959
      window.open(
        `${
          env[process.env.environment].qiye_RPOUTE_API
        }/dynamicDetail?id=${id}&companyNo=${companyNo}`
      );
    },
    imageCutting:imageCutting
  }
};
