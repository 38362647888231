//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { enterpriseHttp } from "@/api/apiEnterpriseService";
import env from "../../../../../../env";
import comSmallTBSke from "../../skeComponents/comSmallTBSke";
import { imageCutting } from "@/util/util.js";
export default {
  name: "specialSession",
  components: {
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comSmallTBSke
  },
  props: {},
  data() {
    return {
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      currentPage: 1,
      defalut_url: require("@/assets/url_empty.png")
    };
  },
  computed: {},
  watch: {},
  created() {
    this.refreshData();
  },
  mounted() {},
  methods: {
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getList();
        this.isClose = true;
        return;
      }
    },
    // 获取货物列表
    async getList() {
      this.loadingStep = true;
      const parmas = {
        typeNo: "",
        page: this.currentPage,
        limit: 12
      };
      const res = await enterpriseHttp.honorList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        return;
      }
      this.loadingStep = false;
    },
    // 进入企业
    toDetail(item) {
      const { id, companyNo } = item;
      window.open(
        `${
          env[process.env.environment].qiye_RPOUTE_API
        }/honorDetails?id=${id}&companyNo=${companyNo}`
      );
    },
    imageCutting:imageCutting
  }
};
