//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let imageCutting = (url, width, height, mode = "fill") => {
  let cutUrl = "";
  if (url) {
    const index = url && url.indexOf(",");
    cutUrl = index !== -1 ? url.substr(0, index) : url;
  }
  let process = "x-oss-process";
  if (cutUrl.includes(".myhuaweicloud.com")) {
    process = "x-image-process";
  }
  return cutUrl
    ? `${cutUrl}?${process}=image/resize,m_${mode},w_${width},h_${height},limit_0`
    : "";
};
const obsImageCutting = (url, width = 0, height = 0, m = "lfit") => {
  if (url) {
    // 缩略
    let newUrl = url.includes('?') ? url.split('?')[0] : url
    let netUrl = ''
    if(url.includes('aliyun')) {
      netUrl = newUrl.replace('https://shigongbang.oss-cn-hangzhou.aliyuncs.com','https://shigongbang.obs.cn-east-3.myhuaweicloud.com')
    }
    newUrl = netUrl?netUrl:newUrl
    let params = `?x-image-process=image/resize,m_${m},w_${width},h_${height}/imageslim`;
    let webPQuery = `/format,webp`
    return `${newUrl}${params}${isSuppNewortWebp ? webPQuery : ""}`
  } else {
    return ""
  }
}
// 是否支持webp 图片
const isSuppNewortWebp = (function () {
  try {
    return (
      document
        .createElement('canvas')
        .toDataURL('image/webp', 0.5)
        .indexOf('data:image/webp') === 0
    )
  } catch (err) {
    return false
  }
})
export default {
  data() {
    return {
      defalut_url: require("@/assets/url_empty.png"),
      zhuanli: {
        1: require("@/assets/famingzhuanli.png"),
        2: require("@/assets/shiyongxinxing.png"),
        3: require("@/assets/waiguansheji.png")
      }
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: Number,
      default: () => 0
    },
    typeName: {
      type: String,
      default: "商标交易"
    }
  },
  created() {},
  mounted() {},
  methods: {
    getList (list) {
      if (!list) return []
      try {
        return JSON.parse(list)
      } catch (err) {
        return []
      }
    },
    toDetail() {
      this.$emit("toDetail", this.item);
    },
    defaultImg() {
      if (this.typeName !== "专利转让") {
        return defalut_url;
      }
    },
    getImg(imgs) {
      if (imgs.indexOf(",") != -1) {
        let img = imgs.split(",");
        // 判断是否为http开头的字符串
        if (img[0].indexOf("http") != -1) {
          return img[0];
        } else {
          return img[0];
        }
      } else {
        if (imgs.indexOf("http") != -1) {
          return imgs;
        } else {
          return imgs;
        }
      }
    },
    imageCutting: imageCutting,
    obsImageCutting: obsImageCutting,
  },
  filters: {
    filterRep(data) {
      if (!data) return "";
      let reg = new RegExp(",|、", "g");
      // return data.replace(/,|、/g, '｜')
      return data.replace(reg, "｜");
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  }
};
