//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      pubEnum: {
        0: '个人', 1: '企业', 2: '团队'
      },
      saleEnum: {
        0: '小规模', 1: '一般纳税人', 2: '出口纳税人', 3:'未核税'
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    toDetail() {
      this.$emit('toDetail', this.item)
    },
  }
};
