//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { tabListEnum } from "./constants";
import commonTab from "../commonTag.vue";

import recommend from "./pages/recommend.vue";
import specialSession from "./pages/specialSession.vue";
import knowledge from "./pages/knowledge.vue";
import honor from "./pages/honor.vue";
import dynamic from "./pages/dynamic.vue";
// import property from "./pages/property.vue";
import specialAssets from "./pages/specialAssets.vue"

export default {
  name: "enterpriseService",
  components: {
    commonTab,
    specialSession,
    recommend,
    knowledge,
    honor,
    dynamic,
    specialAssets,
    // property
  },
  props: {},
  data() {
    return {
      tabList: tabListEnum,
      tabIndex: 0,
      allComponents: [
        "recommend",
        "specialSession",
        "knowledge",
        "specialAssets",
        "honor",
        "dynamic"
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
