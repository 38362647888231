//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { enterpriseHttp } from "@/api/apiEnterpriseService";
import env from "../../../../../../env";
import comImgSke from "../../skeComponents/comImgSke.vue";
import notData from "../../noData";
import localStorage from "@/storage/localStorage";
export default {
  name: "recommend",
  components: {
    notData,
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comImgSke,
  },
  props: {},
  data() {
    return {
      isLoadData: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      currentPage: 1,
      isClose: false,
      isMore: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.refreshData();
  },
  mounted() {},
  methods: {
    goEnterprise(item) {
      const { id, no } = item;
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL}/company/${id}?no=${no}`
      );
    },
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getRecommendList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getRecommendList();
        this.isClose = true;
        return;
      }
    },
    // 获取货物列表
    async getRecommendList() {
      this.loadingStep = true;
//       {"areaNos":[],"cityNo":"","provinceNo":"","isAll":false,"industryCategory1":"","industryCategory2":"","lastCategoryNo":"","distance":"","longitude":108.948024,"latitude":34.263161,"page":1,"limit":10,"name":""}
// {"isAll":false,"limit":3,"page":1}
      let position = JSON.parse(localStorage.read("positionHome"));
      const parmas = {
        isAll: false,
        limit: 3,
        longitude:position.lng,
        latitude:position.lat,
        page: this.currentPage,
      };
      const res = await enterpriseHttp.recommendList(parmas);
      console.log(res)
      const { data, code, msg } = res.data;
      console.log(data)
      if (+code === 200) {
        const list = data.content || [];
        this.isLoadData = data.content.length > 0;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.totalElements;
        this.loadingStep = false;
        return;
      }
      this.loadingStep = false;
    },
    // 更多企业
    goMoreCompany() {
      window.open(`${env[process.env.environment].qiye_RPOUTE_API}/mergMenu`);
    },
    // 兼容注册资本老数据乱输入问题
    filterRegisteredCapital(val) {
      if (!isNaN(parseFloat(val)) && isFinite(val)) {
        return val + '万元'
      } else {
        return val
      }
    },
    filterBtnName(val, item) {
      if (val.key === 3) {
        return item.follow ? '已关注' : '关注企业'
      } else {
        return val.name
      }
    },
  },
};
