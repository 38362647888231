//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
import env from "../../../../../../../env.js";
export default {
  data() {
    return {
      defaultImg: require("@/assets/qiyewz.png"),
      // caseTap: [{ name: "店铺服务" }, { name: "店铺案例" }],
      activeIndex: "shopService",
      list: [],
      imageCutting
    };
  },
  props: {
    item: {
      default: Object,
      type: () => {}
    }
  },
  computed: {
    caseTap() {
      let obj = {
        shopService: this.item.shopService || [], //店铺服务
        shopCases: this.item.shopCases || [] //店铺案例
      };
      let list = [];
      for (let k in obj) {
        if (obj[k].length > 0) {
          list.push({
            name: k === "shopService" ? "店铺服务" : "店铺案例",
            list: obj[k],
            key: k
          });
        }
      }
      if (list.length > 0) {
        this.list = list[0]["list"];
        this.activeIndex = list[0]["key"];
      }
      return list;
    }
  },
  filters: {
    publishType(val) {
      let obj = {
        0: "个人",
        1: "企业",
        2: "团队",
        3: "组织"
      };
      return obj[val];
    }
  },
  methods: {
    go2Detail() {
      const { id, no } = this.item;
      console.log('this.item', this.item)
      // return 
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      window.open(`${url}/company/?no=${no}&subMenuId=6&type=1&thirdMenu=serve`);
    },
    getImage(item) {
      console.log("item", item);
      if (item.serviceCover) {
        try {
          return JSON.parse(item.serviceCover)[0];
        } catch (error) {
          return "";
        }
      } else if (item.caseMainPicture) {
        try {
          return JSON.parse(item.caseMainPicture)[0];
        } catch (error) {
          return "";
        }
      } else {
        return "https://shigongbang-software.obs.cn-east-3.myhuaweicloud.com/bi/report/2022-03-03/1499303514759929856.png";
      }
    },
    setList(item, index) {
      this.activeIndex = item.key;
      this.list = item.list;
    }
  }
};
